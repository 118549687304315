import React from 'react'
import Box from '../components/Box'
import Text from '../components/Text'
import Link from '../components/Link'
import Container from '../components/Container'
import { responsive } from '../components/ThemeProvider/theme'
import BaronFooter from '../containers/BaronFooter'

const BaronCohenResult = ({ location: { state } }) => {
  const score = state?.scores.reduce((res, s) => {
    res = res + s
    return res
  }, 0)
  return (
    <Container maxWidth={responsive('auto', '30em')} px={responsive('2em', '3em')} py="3em" height="100vh">
      <Text textAlign={'center'} my="0.625em" fontSize={'2em'}>你的分數：{score}</Text>
      <Text>
        此測驗最高得分是50分，根據研究發現，80%的亞斯個案分數會大於32分。
        <Text.Inline mb="2em" color="red">
          此量表是三項篩檢工具的其中之一，結果無法代表最終診斷，若有任何疑問應直接尋求專家諮詢。
        </Text.Inline>
      </Text>
      <Text textAlign={'center'} fontSize={'1.5em'} my="0.75em">想知道更多關於亞斯伯格的基本知識嗎，你可以來看看這個：</Text>
      <Box textAlign={'center'} mb="4rem" fontSize={'1.5em'}>
        <Link href="https://goo.gl/tJ2OyQ">https://goo.gl/tJ2OyQ</Link>
      </Box>
      <BaronFooter />
    </Container>
  )
}

export default BaronCohenResult
